import USER_MODELS from './models'
import dateHandler from '../utils/dateHandler'
import validateEmail from '../utils/emailValidation'
import validatePhone from '../utils/validatePhone'
import timeHandler from '../utils/timeHandler'
import validatePassword from '../utils/passwordValidation'
import { createNextState } from '@reduxjs/toolkit'

const checkProductData = (data, type) => {
  let error = []
  const KEYS = [
    'seasonCode',
    'price',
    'location',
    'selectDates',
    'startTime',
    'endTime',
    'course',
    'cap'
  ]

  KEYS.forEach((key) => {
    if (!(key in data) || data[key] === '' || data[key] === null) {
      error.push(key)
    }
    if (key === 'selectDates') {
      if (data[key].length == 0) {
        error.push('invalidDate')
      } else {
        data[key].forEach((date) => {
          if (dateHandler.invalidDate(date)) {
            error.push('invalidDate')
          }
        })
        //TO DO Decide if this is an uncessary check, select dates is sorted in order
        if (
          data.selectDates[0] - data.selectDates[data.selectDates.length - 1] >
          0
        ) {
          error.push('dateRangeError')
        }

        let today = new Date()
        if (today - data.selectDates[0] >= 0 && type !== 'edit') {
          error.push('inThePast')
        }
      }
    }

    if (key === 'price') {
      let check = parseFloat(data[key])
      if (isNaN(check)) {
        error.push(key)
      }
    }
    if (data['startTime'] && data['endTime'] && key === 'startTime') {
      if (timeHandler.invalidTimeRange(data['startTime'], data['endTime'])) {
        error.push('timeRangeError')
      }
    }
  })

  return error
}

const checkStudentData = (data, ignorePass) => {
  let error = []

  const KEYS = ['fName', 'lName', 'dob', 'waiver']

  if (!ignorePass) {
    KEYS.push('password')
  }
  KEYS.forEach((key) => {
    if (!(key in data) || data[key] === '') {
      error.push(key)
    }
    if (key === 'waiver') {
      if (!data[key]) {
        error.push('waiver')
      }
    }
    if (key === 'dob') {
      if (dateHandler.invalidDate(data[key])) {
        error.push('invalidDate')
      }
      if (dateHandler.isTooOld(data[key])) {
        error.push('tooOld')
      }
    }
  })
  return error
}

const student = (data) => {
  let tmp = JSON.parse(JSON.stringify(USER_MODELS.STUDENT_MODEL)) //makes copy of model

  tmp.info.name.fName = data.fName
  tmp.info.name.mName = data.mName
  tmp.info.name.lName = data.lName
  tmp.info.contact.email = data.email
  tmp.info.contact.phone = data.phone
  tmp.pNotes = data.pNotes
  tmp.misc.vax = data.vax
  tmp.parents.push(data.parentID)
  tmp.info.dob = data.dob
  tmp.userName = data.email
  // tmp.pw = data.password
  tmp.info.address.city = data.address.city
  tmp.info.address.country = data.address.country
  tmp.info.address.lineOne = data.address.lineOne
  tmp.info.address.lineTwo = data.address.lineTwo
  tmp.info.address.postal = data.address.postal
  tmp.info.address.state = data.address.state
  tmp.waiver.accepted = data.waiver

  tmp.createdAt = Date.now()
  tmp.updatedAt = Date.now()
  tmp.waiver.acceptedAt = Date.now()

  return tmp
}

const checkParentData = (data, ignorePass) => {
  let error = []
  const KEYS = [
    'fName',
    'lName',
    'email',
    'lineOne',
    'city',
    'postal',
    // 'country',
    'phone',
    'state'
  ]
  if (!ignorePass) {
    KEYS.push('password1')
    KEYS.push('password2')
  }
  KEYS.forEach((key) => {
    if (!(key in data) || data[key] === '') {
      error.push(key)
    }
    if (key === 'phone' && data[key]) {
      if (!validatePhone(data[key])) {
        error.push('phone')
      }
    }
    if (key === 'email') {
      if (!validateEmail(data[key])) {
        error.push('email')
      }
    }
    if (key === 'password1') {
      if (!validatePassword(data[key])) {
        error.push('password1')
      }
    }
    if (key === 'password2') {
      if (data.password1 && data.password2) {
        if (data.password1 !== data.password2) {
          error.push('password2')
        }
      } else {
        error.push('password2')
      }
    }
  })
  return error
}

const parent = (data) => {
  let tmp = USER_MODELS.PARENT_MODEL

  tmp.info.name.fName = data.fName
  tmp.info.name.mName = data.mName
  tmp.info.name.lName = data.lName
  tmp.userName = data.email
  tmp.info.contact.email = data.email
  tmp.info.contact.phone = data.phone
  tmp.misc.vax = data.vax
  tmp.userName = data.email
  tmp.pw = data.password1
  tmp.info.address.city = data.city
  tmp.info.address.country = data.country
  tmp.info.address.lineOne = data.lineOne
  tmp.info.address.lineTwo = data.lineTwo
  tmp.info.address.postal = data.postal
  tmp.info.address.state = data.state
  tmp.eula.accepted = data.eula
  tmp.survey = data.survey

  tmp.createdAt = Date.now()
  tmp.updatedAt = Date.now()
  tmp.eula.acceptedAt = Date.now()

  tmp.info.contact.alt = data.alt
    ?.filter((obj) => Object.keys(obj).some((key) => obj[key] !== ''))
    .map((alt) => ({
      email: alt.secondaryEmail,
      phone: alt.secondaryPhone,
      fName: alt.secondaryFName,
      lName: alt.secondaryLName
    }))

  return tmp
}

const ModelHandler = {
  student,
  checkStudentData,
  parent,
  checkParentData,
  checkProductData
}

export default ModelHandler
