import React, { useEffect } from 'react'
import { Box } from '@mui/material'
import Button from '@mui/material/Button'
import '../index.css'
import InviteNotification from '../component/inviteNotification'
import { useSelector } from 'react-redux'
import ReactGA from 'react-ga4'
import { useNavigate } from 'react-router-dom'
import InstructorLandingPage from './instructor/instructorLanding'
import ReactPixel from 'react-facebook-pixel'

const LandingPage = ({ parent, invites }) => {
  const isLoggedIn = useSelector((state) => state.login.isLoggedIn)
  const user = useSelector((state) => state.login.user)

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: '/home', title: 'home' })
    ReactPixel.pageView()
  }, [])

  const navigate = useNavigate()

  const SIGNED_OUT_LANDING = [
    // { value: 'Holiday Camps', id: '/holiday-camps', img: 'blue-gears.png' },
    // { value: 'Spring Camps', id: '/spring-camps', img: 'plants.png' },
    { value: 'Summer Camps', id: '/summer-camps', img: 'light-bulb.png' },
    { value: 'Memberships', id: '/memberships', img: 'pencil-gear.png' }, //goes to subscriptions container
    { value: 'Other Activities', id: '/upcoming-camps', img: 'tools.png' }
  ]

  // const INSTRUCTOR_LANDING = [
  //   { value: 'Memberships', id: 'admin-memberships' }, //goes to subscriptions container
  //   { value: 'Summer Camps', id: 'summer-overview' },
  //   { value: 'Holiday Camp', id: 'holiday-overview' }
  //   // { value: 'Spring Camps', id: 8 }
  // ]

  const ADMIN_LANDING = [
    { value: 'Workshops', id: '/workshops', img: 'light-bulb.png' },
    {
      value: 'Memberships',
      id: '/admin-memberships/all',
      img: 'pencil-gear.png'
    }, //goes to subscriptions container
    {
      value: 'Lessons',
      id: 'https://rollingrobots.notion.site/Moving-Lessons-Over-from-com-2638e326d3e04dfd89bc533135ea5713?pvs=74',
      newTab: true,
      img: 'books.png'
    }
  ]

  const landingButton = (item, index) => {
    return (
      <Box
        key={`${item.id}-index`}
        onClick={() => {
          if (item.id) {
            if (item.newTab) {
              window.open(item.id)
            } else {
              navigate(`${item.id}`)
            }
          }
        }}
        sx={{
          '&:hover': {
            cursor: 'pointer'
          },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '20px 20px'
        }}
      >
        <Box
          component='img'
          //converts the image name into readable alt text
          alt={
            item.img?.replace('-', ' ').substring(0, item.img.length - 4) ||
            'wrench gear'
          }
          src={require(`../images/miscIcons/${item.img || 'wrench-gear.png'}`)}
          sx={{ width: '100px', marginBottom: '20px' }}
        />
        <Button variant='contained' className='buttonStyle'>
          {item.value}
        </Button>
      </Box>
    )
  }

  const buttons = () => {
    let BUTTON_LIST = SIGNED_OUT_LANDING
    if (isLoggedIn && user.permissions[0] === 4) {
      BUTTON_LIST = ADMIN_LANDING
    }
    return (
      <>
        {isLoggedIn && user.permissions[0] === 3 ? (
          <InstructorLandingPage />
        ) : (
          <Box
            sx={{
              display: 'grid',
              width: '90%',
              gridTemplateColumns: {
                xs: 'repeat(1, minmax(200px, 400px))',
                sm: 'repeat(2, minmax(200px, 400px))'
              },
              justifyContent: 'center',
              marginTop: '20px'
            }}
          >
            {BUTTON_LIST.map((item) => {
              /* eslint-disable-next-line */
              if (item.value == 'Sign In' && isLoggedIn) {
                return null
              } else {
                return landingButton(item)
              }
            })}
          </Box>
        )}
      </>
    )
  }

  //OnClick Memberships,

  return (
    <div>
      {buttons()}
      {invites ? (
        invites.length > 0 ? (
          <InviteNotification invites={invites} accountView={false} />
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
    </div>
  )
}

export default LandingPage
