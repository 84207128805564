import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Grid,
  Typography,
  Divider,
  Button,
  IconButton,
  DialogActions,
  Dialog,
  DialogTitle,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  MenuList,
  MenuItem,
} from '@mui/material'
import { Close, ExpandMore, Person } from '@mui/icons-material'
import CONSTANTS from '../../constants'
import generateUserInfo from '../../utils/genUserInfo'
import ageHandler from '../../utils/ageFormatting'
import SubscriptionBox from './subscriptionBox'
import { deleteChild } from '../../api/parentApi'
import { setSelectedUser } from '../../store/userSlice'
import CourseBox from './courseBox'
import cards from '../util/cards'
import dateHandler from '../../utils/dateHandler'
import RecommendForMembershipForm from './recommendForMembershipForm'

const StudentInfo = (props) => {
  let data = props.data
  let courseData = props.courseData
  let permissions = props.permissions
  let handleEdit = props.handleEdit
  let index = props.index
  let type = props.type
  const dispatch = useDispatch()
  let BigCard = cards.bigCard
  const selectedUser = useSelector((state) => state.user.selectedUser)

  const [studentToDelete, setStudentToDelete] = useState(null)
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)

  const [showRecommend, setShowRecommend] = useState(false)
  const [recommenderNote, setRecommenderNote] = useState('')
  const [noteError, setNoteError] = useState(false)

  const handleCloseRecommend = () => {
    setShowRecommend(false)
    setRecommenderNote('')
  }

  const hasRecommendations = (student) => {
    if (student.recommendation) {
      if (Object.keys(student.recommendation).length > 0) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  const handleSave = async (data, recommenderNote) => {
    let resp = await props.handleSaveRecommendation(data, recommenderNote)
    if (resp === 'success') {
      handleCloseRecommend()
    } else if (resp === 'noteError') {
      setNoteError(true)
    }
  }

  const handleDelete = (student) => {
    setStudentToDelete(student)
    setShowConfirmDelete(true)
  }

  const handleDeleteClose = () => {
    setStudentToDelete(null)
    setShowConfirmDelete(false)
  }

  const handleConfirmDelete = async () => {
    setDeleteLoading(true)
    let resp = await deleteChild({
      parentID: selectedUser._id,
      studentID: studentToDelete._id,
    })
    if (resp.success) {
      let tmpStudents = [...selectedUser.students]
      tmpStudents.splice(
        tmpStudents.findIndex(function (i) {
          return i._id === studentToDelete._id
        }),
        1
      )
      dispatch(
        setSelectedUser({
          _id: selectedUser._id,
          permissions: selectedUser.permissions,
          userName: selectedUser.userName,
          pwHash: selectedUser.pwHash,
          active: selectedUser.active,
          createdAt: selectedUser.createdAt,
          updatedAt: selectedUser.updatedAt,
          info: selectedUser.info,
          eula: selectedUser.eula,
          hasPaymentIssue: selectedUser.hasPaymentIssue,
          paymentMeta: selectedUser.paymentMeta,
          children: selectedUser.children,
          __v: selectedUser.__v,
          memberships: selectedUser.memberships,
          students: tmpStudents,
          inviteInfo: selectedUser.inviteInfo,
        })
      )
      setDeleteLoading(false)
      setShowConfirmDelete(false)
      setStudentToDelete(null)
    } else {
      setDeleteLoading(false)
      alert(resp)
    }
  }

  const styles = {
    button: {
      backgroundColor: CONSTANTS.RED_HEX,
      padding: '5px',
      margin: '5px',
    },
    title: {
      marginTop: '15px',
    },
    paper: {},
  }

  let activeCourses = []
  if (data?.courseInfo?.length > 0) {
    let tmpDate = dateHandler.getToday().minus({ days: 2 })
    activeCourses = data.courseInfo.filter(
      (course) => dateHandler.getDateTimeObj(course.endDate) > tmpDate
    )
  }

  const hasCourses = () => {
    if (
      data.courses?.active.length > 0 ||
      data.courses?.completed.length > 0 ||
      data.subscriptions?.active.length > 0 ||
      data.subscriptions?.inactive.length > 0
    ) {
      return true
    } else {
      return false
    }
  }

  const handleStatus = (value, credit) => {
    //Used by Alternate Payment Modal to update a students credit confirmation status on client side
    let productIndex
    let tmpUser = JSON.parse(JSON.stringify(selectedUser)) //make a mutuable copy of selectedUser to update
    if (credit.productType == 'subscription') {
      productIndex = selectedUser.students[index].subscriptionInfo.findIndex(
        (obj) => obj._id == credit.documentID
      )
      tmpUser.students[index].subscriptionInfo[productIndex].credit.confirmed =
        value
    } else if (credit.productType == 'workshop') {
      productIndex = selectedUser.students[index].courseInfo.findIndex(
        (obj) => obj.prodID == credit.documentID
      )
      tmpUser.students[index].courseInfo[productIndex].credit.confirmed = value
    }
    dispatch(setSelectedUser(tmpUser))
  }
  const accordionContent = () => {
    let tmpDate = new Date()
    tmpDate.setDate(tmpDate.getDate() - 2)
    let pastCourses = []
    pastCourses = data?.courseInfo?.filter(
      (course) => new Date(course.endDate) < tmpDate
    )
    return (
      <>
        {pastCourses?.length > 0 ? (
          <Accordion
            disableGutters
            sx={{
              padding: '5px 5px',
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls='inactive-subscriptions-content'
              id='inactive-subscriptions-header'
            >
              <Typography variant='h5' align='left'>
                Past Courses
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <CourseBox
                courses={pastCourses}
                courseData={courseData}
                permissions={permissions}
                styles={styles}
                student={data.info}
                studentID={data._id}
                past={true}
                handleStatus={handleStatus}
              />
            </AccordionDetails>
          </Accordion>
        ) : (
          <></>
        )}
        {data.inactiveSubscriptions?.length > 0 ? (
          <Accordion disableGutters sx={{ padding: '5px 5px' }}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls='inactive-subscriptions-content'
              id='inactive-subscriptions-header'
            >
              <Typography variant='h5' align='left'>
                Inactive Memberships
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {data.inactiveSubscriptions.map((sub, index) => {
                return (
                  <React.Fragment key={sub._id}>
                    <SubscriptionBox
                      subscription={sub}
                      allActivities={data.activityInfo}
                      inactive={true}
                      data={data}
                      permissions={permissions}
                      handleStatus={handleStatus}
                    />
                    {data.inactiveSubscriptions.length - 1 !== index ? (
                      <Divider sx={{ marginTop: '10px' }} />
                    ) : (
                      <></>
                    )}
                  </React.Fragment>
                )
              })}
            </AccordionDetails>
          </Accordion>
        ) : (
          <></>
        )}
      </>
    )
  }

  const editMenuContent = () => {
    return (
      <MenuList>
        {permissions !== 3 ? (
          <>
            <MenuItem onClick={() => handleEdit(data, type, index)}>
              Edit
            </MenuItem>
            {/**Allow parent to delete student if not enrolled in any workshops */}
            <MenuItem
              onClick={() => handleDelete(data)}
              disabled={hasCourses()}
            >
              Delete
            </MenuItem>
          </>
        ) : (
          <></>
        )}
        {permissions >= 3 ? (
          <MenuItem onClick={() => setShowRecommend(true)}>
            Recommend Student for Membership
          </MenuItem>
        ) : null}
      </MenuList>
    )
  }
  return (
    <>
      <BigCard
        icon={Person}
        title={generateUserInfo.generateName(data.info.name)}
        id='student-info'
        accordionContent={accordionContent()}
        editMenu={editMenuContent()}
      >
        <Grid container sx={{ padding: '5px 5px' }}>
          {permissions >= 3 ? (
            <>
              <Grid item xs={4}>
                <Typography textAlign='left' fontWeight='bold'>
                  Student ID
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography textAlign='left'>{data._id}</Typography>
              </Grid>
            </>
          ) : (
            <></>
          )}
          <Grid item xs={4}>
            <Typography textAlign='left' fontWeight='bold'>
              Birthday
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography textAlign='left'>{data.info.dob}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography textAlign='left' fontWeight='bold'>
              Age
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography textAlign='left'>
              {ageHandler.getAge(data.info.dob)}
            </Typography>
          </Grid>
          {data.pNotes ? (
            <>
              <Grid item xs={4}>
                <Typography textAlign='left' fontWeight='bold'>
                  Parent Notes
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography textAlign='left'>{data.pNotes}</Typography>
              </Grid>
            </>
          ) : (
            <></>
          )}
          {data.iNotes && permissions >= 3 ? (
            <>
              <Grid item xs={4}>
                <Typography textAlign='left' fontWeight='bold'>
                  Instructor Notes
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography textAlign='left'>{data.iNotes}</Typography>
              </Grid>
            </>
          ) : (
            <></>
          )}
        </Grid>
        {hasRecommendations(data) ? (
          <Grid
            container
            sx={{
              backgroundColor: '#eef6fb',
              borderRadius: '5px',
              padding: '5px 5px',
              textAlign: 'left',
            }}
          >
            <Grid item xs={12} sm={8} md={10} sx={{ display: 'flex' }}>
              <Typography variant='h5' sx={{ flexGrow: 1, textAlign: 'left' }}>
                Membership Recommendation
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <Typography sx={{ textAlign: { xs: 'left', sm: 'right' } }}>
                {dateHandler.toUSDate(data.recommendation.recommendedDate)}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={4}>
              <Typography fontWeight={'bold'}>Recommended by</Typography>
            </Grid>
            <Grid item xs={6} sm={8}>
              <Typography>
                {data.recommenderInfo.name
                  ? `${data.recommenderInfo.info.name.fName}
                ${data.recommenderInfo.info.name.lName}`
                  : 'Admin'}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography fontWeight={'bold'}>Note</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography>{data.recommendation.recommenderNote}</Typography>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
        {data.subscriptionInfo?.length >= 1 ? (
          <>
            <Typography variant='h3' align='left' sx={styles.title}>
              Memberships
            </Typography>
            <Divider sx={{ marginBottom: '10px' }} />
            {data.subscriptionInfo?.map((sub, index) => {
              return (
                <React.Fragment key={sub._id}>
                  <SubscriptionBox
                    subscription={sub}
                    allActivities={data.activityInfo}
                    data={data}
                    permissions={permissions}
                    handleStatus={handleStatus}
                  />
                  {data.subscriptionInfo?.length - 1 !== index ? (
                    <Divider sx={{ marginTop: '10px' }} />
                  ) : (
                    <></>
                  )}
                </React.Fragment>
              )
            })}
          </>
        ) : (
          <></>
        )}

        {activeCourses.length > 0 ? (
          <CourseBox
            courses={activeCourses}
            courseData={courseData}
            permissions={permissions}
            styles={styles}
            student={data.info}
            studentID={data._id}
            handleStatus={handleStatus}
          />
        ) : (
          <></>
        )}
      </BigCard>

      <RecommendForMembershipForm
        showRecommend={showRecommend}
        handleCloseRecommend={handleCloseRecommend}
        hasRecommendations={hasRecommendations}
        data={data}
        handleSave={handleSave}
        noteError={noteError}
        recommenderNote={recommenderNote}
        setRecommenderNote={setRecommenderNote}
      />
      <Dialog
        open={showConfirmDelete}
        sx={{
          '& .MuiBackdrop-root': {
            backgroundColor: 'rgba(240, 240, 240, 0.3)',
          },
          '& .MuiPaper-root': { boxShadow: 'none' },
        }}
        fullWidth
        maxWidth='xs'
      >
        <DialogTitle style={{ textAlign: 'center' }}>
          Delete {studentToDelete?.info.name.fName}{' '}
          {studentToDelete?.info.name.lName}
          <IconButton
            style={{ position: 'absolute', right: '20px', top: '12px' }}
            onClick={() => {
              handleDeleteClose()
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogActions
          style={{ display: 'flex', justifyContent: 'space-around' }}
        >
          <Button
            onClick={() => {
              handleDeleteClose()
            }}
          >
            Go Back
          </Button>
          <Button disabled={deleteLoading} onClick={handleConfirmDelete}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default StudentInfo
