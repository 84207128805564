import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { resetProduct, setProduct } from '../../store/workshopSlice'
import { Box } from '@mui/material/'
import ProductForm from '../../component/adminViews/products/productForm'
import { createProduct } from '../../api/admin'
import ProductsTable from '../../component/adminViews/products/productsTable'
import SecondaryNav from '../../component/util/secondaryNav'
import productHandler from '../../utils/productHandler'
import {
  // setWorkshops,
  setFilteredWorkshops,
  setDate
} from '../../store/workshopSlice'
import { GetAllWorkshopsFullInfo } from '../../api/products'
import timeHandler from '../../utils/timeHandler'

const AdminProductsContainer = () => {
  const dispatch = useDispatch()
  const defaultProduct = {
    seasonCode: '',
    workshop: '',
    location: '',
    course: '',
    startDate: null,
    endDate: null,
    selectDates: [],
    startTime: '9:00',
    endTime: '12:00',
    cap: 10,
    price: '',
    instructors: []
  }
  const [product, setProduct] = useState({ ...defaultProduct })

  const [showForm, setShowForm] = useState(false)
  const [type, setType] = useState('save')
  const [loading, setLoading] = useState(true)
  const [workshopsCount, setWorkshopsCount] = useState(0)
  const [pagination, setPagination] = useState(0)
  const [pageWorkshops, setPageWorkshops] = useState([])

  // const products = useSelector((state) => state.workshop.workshops) // TO DO: what is this for?
  const date = useSelector((state) => state.workshop.date)
  const filteredProducts = useSelector(
    (state) => state.workshop.filteredWorkshops
  )
  const uID = useSelector((state) => state.login.user._id)
  const [currTab, setCurrTab] = useState(0)
  const defaultRegion = useSelector((state) => state.login.defaultRegion)
  const locations = useSelector((state) => state.user.locations)

  const [limit, setLimit] = useState(25) //Eventually bring back in rows per page selection using this. See productsTable pagination

  //TO DO: Decide if this can be removed with new data structure
  //First get date six months ago to only retreieve workshops from last six months (on first page load)
  //Used by productDate.js
  useEffect(() => {
    let tmpDate = new Date()
    tmpDate.setMonth(tmpDate.getMonth() - 6)
    dispatch(setDate(tmpDate.toISOString()))
  }, [])

  //Once the year is determined, the API call will retrieve the workshops
  useEffect(() => {
    async function handleAsync() {
      // let allWorkshops
      let startTimer = new Date().getTime()
      setLoading(true)
      // Some workshops already fetched
      if (pagination >= 0 && filteredProducts[pagination * limit]) {
        // 2 is temp for page size
        setPageWorkshops(
          filteredProducts.slice(
            pagination * limit,
            Math.min(pagination * limit + limit, workshopsCount)
          )
        )
      } else {
        let startingAfter = null
        if (filteredProducts.length > 0) {
          startingAfter = filteredProducts[filteredProducts.length - 1]._id
        }
        let resp = await GetAllWorkshopsFullInfo({
          date: date,
          startingAfter: startingAfter
        })
        if (resp.data) {
          // allWorkshops = [...filteredProducts, ...resp.data.workshops]
          // dispatch(setWorkshops(allWorkshops))
          setWorkshopsCount(resp.data.count)
          let updateFilteredWorkshops = [
            ...filteredProducts,
            ...resp.data.workshops
          ]
          dispatch(setFilteredWorkshops(updateFilteredWorkshops))
          setPageWorkshops(resp.data.workshops) // TO DO: account for last page potentially having less workshops
        }
        // let filteredWorkshops = allWorkshops
        // if (defaultRegion._id && locations.length > 0) {
        //   let regionLocations = []
        //   if (defaultRegion.name === 'All') {
        //     regionLocations = locations.map((tmpLocation) => tmpLocation._id)
        //   } else {
        //     regionLocations = locations
        //       .filter((loc) => loc.region === defaultRegion._id)
        //       .map((filteredLoc) => filteredLoc._id)
        //   }

        //   filteredWorkshops = allWorkshops.filter((workshop) =>
        //     regionLocations.includes(workshop.metadata.location)
        //   )
        // }
      }
      console.log(`${new Date().getTime() - startTimer} ms`)

      setLoading(false)
    }

    if (date && locations.length > 0) {
      handleAsync()
    }
  }, [date, defaultRegion, locations, pagination])
  useEffect(() => {
    if (pagination >= 0 && filteredProducts[pagination * limit]) {
      // 2 is temp for page size
      setPageWorkshops(
        filteredProducts.slice(pagination * limit, pagination * limit + limit)
      )
    }
  }, [filteredProducts])
  const handleClose = () => {
    setCurrTab(0)
    setType('save')
    setShowForm(false)
    setProduct({ ...defaultProduct })
  }

  const handleSeason = (prod) => {
    //Uses the startdate to get the year and return the recamp season
    let startDate = new Date(prod.startDate)
    let workshopValue = prod.seasonCode
    let year = startDate.getFullYear()
    //Holiday camps in Jan, Feb assigned to previous year season
    if (workshopValue.includes('holiday') && startDate.getMonth() <= 2) {
      year = year - 1
    }
    if (workshopValue.includes('_')) {
      workshopValue = workshopValue.split('_')[0] + '_' + year
    } else {
      workshopValue = workshopValue + '_' + year
    }
    return workshopValue
  }
  const handleSave = async (isEdit, course) => {
    let tmpProduct = { ...product }
    let season = handleSeason(product)
    tmpProduct.workshop = season
    tmpProduct.price = (tmpProduct.price * 100).toFixed(0)
    tmpProduct.uID = uID

    let [startH, startM] = tmpProduct.startTime.split(':')
    tmpProduct.startDate = timeHandler.setTime(
      tmpProduct.startDate,
      startH,
      startM
    )
    tmpProduct.endDate = timeHandler.setTime(tmpProduct.endDate, startH, startM)
    // Use end time to save enddate
    tmpProduct.selectDates = tmpProduct.selectDates.map((date) => {
      let tmp = timeHandler.setTime(date, startH, startM)
      return tmp.toUnixInteger().toString() //Save as String to be consistent with Stripe
    })

    let nameString = `${tmpProduct.locationName} ${
      tmpProduct.courseName
    } ${tmpProduct.startDate.toLocaleString()}-${tmpProduct.endDate.toLocaleString()} ${
      tmpProduct.startTime
    }-${tmpProduct.endTime}`
    tmpProduct.nameString = nameString

    // Convert to Unix string to save in Stripe
    tmpProduct.startDate = tmpProduct.startDate.toUnixInteger().toString()
    tmpProduct.endDate = tmpProduct.endDate.toUnixInteger().toString()

    if (isEdit) {
      let resp = await productHandler.update(tmpProduct, course, uID)
      if (resp.success) {
        //update products
        // let index = products.findIndex((prod) => prod.id === resp.data.id)
        // let tmpProducts = [...products]
        // tmpProducts.splice(index, 1, resp.data)
        // dispatch(setWorkshops(tmpProducts))

        //updated filtered products
        let tmpProducts = [...filteredProducts]
        let index = tmpProducts.findIndex(
          (prod) => prod.stripeID === resp.data.id
        )
        tmpProducts.splice(index, 1, resp.data)
        dispatch(setFilteredWorkshops(tmpProducts))

        setProduct(defaultProduct)
        handleClose()
      } else {
        alert('failed update')
      }
    } else {
      let resp = await createProduct(tmpProduct)
      if (resp.success) {
        resp.data.priceInfo = resp.data.price
        delete resp.data.price
        let tmpProducts = [...filteredProducts]
        tmpProducts.unshift(resp.data)
        dispatch(setFilteredWorkshops(tmpProducts))
        handleClose()
        setCurrTab(0)
      } else {
        alert('failed creation')
      }
    }
  }

  const setForm = (item, passedType) => {
    let formattedProduct = productHandler.formatProduct(item)
    setProduct(formattedProduct)
    setShowForm(true)
    setType(passedType)
  }

  const TABS = [
    {
      label: 'Search',
      content: (
        <>
          <ProductsTable
            pageWorkshops={pageWorkshops}
            setForm={setForm}
            loading={loading}
            pagination={pagination}
            setPagination={setPagination}
            workshopsCount={workshopsCount}
          />
        </>
      )
    },
    {
      label: 'Add Workshop',
      content: (
        <>
          <Box sx={{ width: '80%', margin: '0px auto 100px auto' }}>
            <ProductForm
              handleClose={handleClose}
              handleSave={handleSave}
              type={type}
              addWorkshop={true}
              product={product}
              setProduct={setProduct}
            />
          </Box>
        </>
      )
    }
  ]

  return (
    <React.Fragment>
      <SecondaryNav
        title='Admin Workshops'
        tabs={TABS}
        currTab={currTab}
        setCurrTab={setCurrTab}
      />
      {showForm ? (
        <>
          <ProductForm
            handleClose={handleClose}
            handleSave={handleSave}
            type={type}
            addWorkshop={false}
            product={product}
            setProduct={setProduct}
          />
        </>
      ) : (
        <></>
      )}
      {TABS[currTab].content}
    </React.Fragment>
  )
}

export default AdminProductsContainer
