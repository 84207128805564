import React from 'react'

const ReferralContainer = () => {
  return (
    <div
      id='referral-container'
      style={{ textAlign: 'left', width: '90%', margin: 'auto auto' }}
    >
      <h1>
        Rolling Robots Referral Program - Help your friends discover Rolling
        Robots' epic robotics programs
      </h1>
      <h2>Earn Rewards by Referring Friends!</h2>
      <p>Updated: Jan. 1, 2025</p>
      <h2>How It Works:</h2>
      <ol>
        <li>
          Share Rolling Robots' program (rollingrobots.com) with friends and
          family.
        </li>
        <li>
          Email referee's NAME and EMAIL to contact@rollingrobots.com with
          subject: Referral by [Your name]
        </li>
        <li>
          The referee will receive an email with an unique 10% off code for them
          to use when they sign up and make their first purchase of $99 or more.
        </li>
        <li>You’ll receive a $25 reward for each successful referral.</li>
      </ol>
      <h2>Terms & Conditions:</h2>
      <ul>
        <li>Only one reward per referred customer.</li>
        <li>
          Referral credits are applied to your account within 7 days of the
          referee's purchase.
        </li>
        <li>Credits expire after 6 months.</li>
        <li>
          Rolling Robots reserves the right to modify or terminate the program
          at any time.
        </li>
      </ul>
      <h2>Start Referring Today!</h2>
    </div>
  )
}

export default ReferralContainer
